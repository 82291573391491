import React from "react";
import GitHubCalendar from "react-github-calendar";

function AcercaPasatiempos() {
  return (
    <div class="calendar">
      <h4>Calendario de Github</h4>
      <GitHubCalendar username="rcuevaspantoja" />
    </div>
  );
}

export default AcercaPasatiempos;
